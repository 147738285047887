import { fallbackSettings } from '@/api/setup/setup-frontend';
import { MusicPage } from '@/components/_pages/MusicPage';
import {
  getMusicPageCacheKey,
  getMusicPageData,
  getMusicPageHeadings,
} from '@/components/_pages/MusicPage/util';
import { HOUR_INTERVAL } from '@/constants/revalidationInterval';

import type { HomePageProps } from '@/components/_pages/MusicPage/types';
import type { GetStaticProps, NextPage } from 'next';

const HomePage: NextPage<HomePageProps> = (props: HomePageProps) => {
  return <MusicPage {...props} />;
};

export const getStaticProps: GetStaticProps<HomePageProps> = async () => {
  const settings = fallbackSettings;

  const initialCacheKey = getMusicPageCacheKey(settings);
  const initialData = await getMusicPageData();

  const initialHeadingsData = getMusicPageHeadings();

  if (
    initialData?.carousels.discover?.length === 0 ||
    initialData?.carousels.homepage?.length === 0
  ) {
    throw new Error('Carousel data not found.');
  }

  return {
    revalidate: HOUR_INTERVAL,
    props: {
      initialCacheKey,
      initialData,
      initialHeadingsData,
    },
  };
};

export default HomePage;
